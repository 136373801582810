//* #region ----------------------------------------------- Types

import type { MyComponentTypes } from "haq"

type T = MU_MyOverlay

type X = MyComponentTypes<T>
type C = T["MyOverlay"]

//* #endregion ----------------------------------------------- Types

//* #region ----------------------------------------------- Module Imports (only utility/helper modules)

import Request from "@helpers/request.js"
import RootDoc from "@helpers/rootDoc.js"
import { $, TypedComponent, defineComponent } from "haq"

//* #endregion ----------------------------------------------- Module Imports (only utility/helper modules)

export class MyOverlay extends HTMLElement {
	private Component: ReturnType<typeof TypedComponent<C>>
	private Content: X["OverlayContent"] | undefined
	private CloseBtn: X["CloseBtn"] | undefined

	private _keyUpHandler

	private REDIRECT_URL: keyof BroadenRenderedRoutesWithParams<RenderedRoutes> | null = null
	private IS_ESCAPABLE = false
	private IS_NOT_CLOSABLE = false
	private HAS_KEYUP_LISTENER = false

	private INITIALIZED = false //handle Astro persisted components using View transitions

	constructor() {
		super()

		this.Component = TypedComponent<C>(this)
		this.Content = $<T["OverlayContent"]>(this, ".overlay-content")[0]
		this.CloseBtn = $<T["CloseBtn"]>(this, ".close-btn")[0]

		this.Component.classList.remove("no-display")
		this._keyUpHandler = this._onKeyUp.bind(this)
	}

	//* ---------- Initialize -----------------------------------------------

	connectedCallback() {
		if (this.INITIALIZED) return

		const closeAble = [this.Component, this.CloseBtn]
		for (const el of closeAble) {
			el?.addEventListener("click", (e) => this._onClose(e))
		}

		this.INITIALIZED = true
	}

	//* ---------- Exposed Methods -----------------------------------------------

	show(isNotClosable?: boolean) {
		this.Component.setAttribute("d_visible", true)
		RootDoc.classList.add("no-scroll")
		if (this.IS_ESCAPABLE && !this.HAS_KEYUP_LISTENER) {
			this.HAS_KEYUP_LISTENER = true
			document.addEventListener("keyup", this._keyUpHandler)
		}

		if (isNotClosable) this.IS_NOT_CLOSABLE = true
	}

	hide() {
		if (this.REDIRECT_URL) {
			Request.goToLocalURL(this.REDIRECT_URL)
			return
		}
		this.IS_NOT_CLOSABLE = false
		this.Component.removeAttribute("d_visible")
		RootDoc.classList.remove("no-scroll")
		if (this.IS_ESCAPABLE) {
			this.HAS_KEYUP_LISTENER = false
			document.removeEventListener("keyup", this._keyUpHandler)
		}
	}

	addEscapeListener() {
		this.IS_ESCAPABLE = true
	}

	//* ---------- Getters/Setters -----------------------------------------------

	set redirect(url: keyof BroadenRenderedRoutesWithParams<RenderedRoutes>) {
		this.REDIRECT_URL = url
	}

	//* ---------- Listeners -----------------------------------------------

	private _onClose(e: Event) {
		if (this.IS_NOT_CLOSABLE) return
		const target = e.target
		if (target === e.currentTarget) this.hide()
	}

	private _onKeyUp(e: KeyboardEvent) {
		if (e.key === "Escape") this.hide()
	}

	//* ---------- DOM Rendering -----------------------------------------------

	render(content: DocumentFragment | string) {
		if (!this.Content) return
		if (typeof content === "string") {
			this.Content.innerHTML = content
		} else {
			this.Content.replaceChildren()
			this.Content.append(content)
		}
		this.show()
	}
}

export default function MyOverlayInit() {
	defineComponent<C>("my-overlay", MyOverlay)
}
